<template>
  <div class="news__wrapper">
    <div class="news__header">
      <!-- <molecule-navigation :items="items"></molecule-navigation> -->
      <div class="news__header-search">
        <input
          type="text"
          name="search-input"
          v-model="searchQuery"
          class="news__header-search-input"
          placeholder="Chercher"
        />
        <atom-icon icon="search" class="news__header-search-icon"> </atom-icon>
      </div>
    </div>
    <div class="news__containers">
      <div
        v-for="(article, index) in filteredNews"
        :key="index"
        class="news__containers-items"
      >
        <img
          :src="imageUrl(article.background.path)"
          :alt="article.title"
          class="news__containers-items-img"
        />
        <h3 class="news__containers-items-headline">
          {{ article.title }}
        </h3>
        <p class="news__containers-items-summary">
          {{ article.summary }}
        </p>
        <atom-link
          :to="{name:'Article', params:{articleId:article.id}}"
          variant="primary underline"
          class="news__containers-items-link"
          >Lire la suite</atom-link
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import MoleculeNavigation from "@/components/molecules/MoleculeNavigation.vue";
import { onBeforeMount, computed, ref, inject } from "vue";
import {useStore} from "vuex";
const {dispatch, state} = useStore();
const searchQuery = ref("");
const news = computed( () => state.news.news?.data);
const imageUrl = inject('imageUrl');
const filteredNews = computed(() => {
  return news.value?.filter((article) => {
    return article.title.match(searchQuery.value);
  });
});

onBeforeMount( async () => {
  await dispatch('news/getAll', {
    page: 1,
    filter:'filter.isAdvocacy=false'
  });
});


const items = [
  {
    link: "/",
    name: "Page d'accueil",
  },
  {
    link: "/news",
    name: "Actualité",
  },
];
</script>

<style lang="scss" scoped>
.news {
  &__wrapper{
    @apply container mx-auto px-4;
  }
  &__containers {
    @apply flex flex-wrap justify-start  
      gap-4 md:gap-8
      mb-10 md:mb-12 lg:mb-15;
    &-items {
      @apply w-[full] sm:w-[calc(50%-10px)] md:w-[calc(50%-20px)]
      xl:w-[calc(33%-25px)] mb-6 lg:mb-11;
      &-img{
        @apply w-full h-60 object-cover cursor-pointer
          sm:h-72
          md:h-80
          rounded-lg
          border border-gray-400;
      }
      &-headline{
        @apply font-medium text-base lg:text-lg mb-1 mt-6;
      }
      &-summary{
        @apply text-sm text-gray-300 mb-4;
      }
    }
  }
  &__header {
    @apply mb-9 mt-6 inline-block;
    &-search {
      @apply relative;
      &-input {
        @apply text-sm font-medium leading-5 rounded-md
        border border-[#D1D5DB] p-[10px] w-[318px] pr-[42px];
        &::placeholder {
          @apply text-black text-sm font-medium;
        }
      }
      &-icon {
        @apply w-4 h-4
        absolute right-2 top-1/2 -translate-y-1/2;
      }
    }
  }
}
</style>